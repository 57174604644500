import Slider from '@/components/slider/Slider';
import { PopularCategory } from '@/settings/popular-categories/types';
import Card from './Card';
import { breakpoints, cartBreakpoints } from './constants';

const Carousel = ({
  popularCategories,
  isCartPage,
}: {
  popularCategories: PopularCategory[];
  isCartPage?: boolean;
}) => (
  <div className="relative mt-6">
    <Slider breakpoints={isCartPage ? cartBreakpoints : breakpoints}>
      {popularCategories.map((category) => (
        <Card category={category} isCartPage={isCartPage} />
      ))}
    </Slider>
  </div>
);

export default Carousel;
